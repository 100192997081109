import React from "react";

interface Props {
  label?: string;
  options: { value: string; label: string }[]; // Änderung der Optionen auf ein Array von Objekten mit Wert und Label
  selectedValue: string;
  setSelectedValue: (value: string) => void;
  selectedAnswers: string[];
  setSelectedAnswers: (answers: string[]) => void;
  index: number;
}

const DropDown: React.FC<Props> = ({
  label,
  options,
  selectedValue,
  setSelectedValue,
  selectedAnswers,
  setSelectedAnswers,
  index,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newSelectedAnswers = [...selectedAnswers];
    newSelectedAnswers[index] = e.target.value;
    setSelectedAnswers(newSelectedAnswers);
    setSelectedValue(e.target.value);
  };

  return (
    <select
      className="block w-full rounded border p-3 ring-1 ring-inset ring-black sm:text-sm h-12 focus:ring-blue-950 focus:ring-1 focus:border-blue-950 focus:ring-inset bg-white"
      id={label}
      value={selectedValue}
      onChange={handleChange}
    >
      {options.map((option, i) => (
        <option key={i} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};
export default DropDown;
