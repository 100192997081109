import React, {
  ChangeEvent,
  ChangeEventHandler,
  Dispatch,
  SetStateAction,
} from "react";
import oldBuilding from "./../svg/age/oldBuilding.svg";
import refurbished from "./../svg/age/refurbished.svg";
import newBuilding from "./../svg/age/newBuilding.svg";

import oil from "./../svg/heating/oil.svg";
import gas from "./../svg/heating/gas.svg";
import electricHeating from "./../svg/heating/electricHeating.svg";
import districtHeating from "./../svg/heating/districtHeating.svg";
import heatPump from "./../svg/heating/heatPump.svg";
import woodHeating from "./../svg/heating/woodHeating.svg";
import woodChipHeating from "./../svg/heating/woodChipHeating.svg";
import pelletHeating from "./../svg/heating/pelletHeating.svg";
import biogasHeating from "./../svg/heating/biogasHeating.svg";

import PersonOverview from "./../svg/overview/PersonOverview.svg";
import SqrMeters from "./../svg/overview/SqrMeters.svg";
import Thumb from "./../svg/overview/Thumb.svg";
import ArrowOverview from "./../svg/overview/ArrowOverview.svg";
import DropDown from "./results/DropDown";
import InputField from "./inputComponents/InputField";

type BuildingType = "oldBuilding" | "refurbished" | "newBuilding";

type HeatingType =
  | "biogasHeating"
  | "electricHeating"
  | "districtHeating"
  | "gas"
  | "woodHeating"
  | "pelletHeating"
  | "woodChipHeating"
  | "oil"
  | "heatPump";

interface Props {
  selectedAnswers: string[];
  sqrMeter: string | number;
  persons: string | number;
  changesPlaned: boolean;
  selectedAnswersChanges: string[];
  sqrMeterChanges: string | number;
  personsChanges: string | number;
  setSelectedAnswers: Dispatch<SetStateAction<string[]>>;
  setSqrMeter: Dispatch<SetStateAction<number | string>>;
  setPersons: Dispatch<SetStateAction<number | string>>;
  setSelectedAnswersChanges: Dispatch<SetStateAction<string[]>>;
  setSqrMeterChanges: Dispatch<SetStateAction<number | string>>;
  setPersonsChanges: Dispatch<SetStateAction<number | string>>;
  setChangesPlaned: Dispatch<SetStateAction<boolean>>;
}

const Overview = (props: Props) => {
  const {
    selectedAnswers,
    sqrMeter,
    persons,
    changesPlaned,
    selectedAnswersChanges,
    sqrMeterChanges,
    personsChanges,
    setSelectedAnswers,
    setSqrMeter,
    setPersons,
    setSelectedAnswersChanges,
    setSqrMeterChanges,
    setPersonsChanges,
    setChangesPlaned,
  } = props;

  const rangeMax = 1000;
  const rangeMin = 20;
  const minPerson = 1;

  const buildingImages: Record<BuildingType, string> = {
    oldBuilding: oldBuilding,
    refurbished: refurbished,
    newBuilding: newBuilding,
  };

  const heatingImages: Record<HeatingType, string> = {
    biogasHeating: biogasHeating,
    electricHeating: electricHeating,
    districtHeating: districtHeating,
    gas: gas,
    woodHeating: woodHeating,
    pelletHeating: pelletHeating,
    woodChipHeating: woodChipHeating,
    oil: oil,
    heatPump: heatPump,
  };

  let translatedBuildingAge = "";

  switch (selectedAnswers[0]) {
    case "oldBuilding":
      translatedBuildingAge = "Altbau";
      break;
    case "newBuilding":
      translatedBuildingAge = "Neubau";
      break;
    case "refurbished":
      translatedBuildingAge = "Saniert";
      break;
    default:
      translatedBuildingAge = selectedAnswers[0];
  }

  let translatedHeatingType = "";

  switch (selectedAnswers[1]) {
    case "biogasHeating":
      translatedHeatingType = "Biogas";
      break;
    case "electricHeating":
      translatedHeatingType = "Elektroheizung";
      break;
    case "districtHeating":
      translatedHeatingType = "Fernwärme";
      break;
    case "gas":
      translatedHeatingType = "Gasheizung";
      break;
    case "woodHeating":
      translatedHeatingType = "Holzheizung";
      break;
    case "pelletHeating":
      translatedHeatingType = "Holzpellets";
      break;
    case "woodChipHeating":
      translatedHeatingType = "Holzschnitzel";
      break;
    case "oil":
      translatedHeatingType = "Ölheizung";
      break;
    case "heatPump":
      translatedHeatingType = "Wärmepumpe";
      break;
    default:
      translatedHeatingType = selectedAnswers[1];
  }
  let translatedBuildingAgeChanges = "";
  let translatedHeatingTypeChanges = "";
  if (changesPlaned) {
    switch (selectedAnswersChanges[4]) {
      case "oldBuilding":
        translatedBuildingAgeChanges = "Altbau";
        break;
      case "newBuilding":
        translatedBuildingAgeChanges = "Neubau";
        break;
      case "refurbished":
        translatedBuildingAgeChanges = "Saniert";
        break;
      default:
        translatedBuildingAgeChanges = selectedAnswersChanges[4];
    }
    switch (selectedAnswersChanges[5]) {
      case "biogasHeating":
        translatedHeatingTypeChanges = "Biogas";
        break;
      case "electricHeating":
        translatedHeatingTypeChanges = "Elektroheizung";
        break;
      case "districtHeating":
        translatedHeatingTypeChanges = "Fernwärme";
        break;
      case "gas":
        translatedHeatingTypeChanges = "Gasheizung";
        break;
      case "woodHeating":
        translatedHeatingTypeChanges = "Holzheizung";
        break;
      case "pelletHeating":
        translatedHeatingTypeChanges = "Holzpellets";
        break;
      case "woodChipHeating":
        translatedHeatingTypeChanges = "Holzschnitzel";
        break;
      case "oil":
        translatedHeatingTypeChanges = "Ölheizung";
        break;
      case "heatPump":
        translatedHeatingTypeChanges = "Wärmepumpe";
        break;
      default:
        translatedHeatingTypeChanges = selectedAnswersChanges[5];
    }
  }

  const handleInputChangeSqr = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;

    if (/^\d*$/.test(newValue)) {
      setSqrMeter(newValue === "" ? "" : Number(newValue));
    }
  };

  const handleInputChangePerson: ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const newIndex = event.target.value;

    if (/^\d*$/.test(newIndex) && Number(newIndex) < 100000000) {
      setPersons(newIndex === "" ? "" : Number(newIndex));
    }
  };

  const handleInputChangeSqrChanges = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = event.target.value;

    if (/^\d*$/.test(newValue)) {
      setSqrMeterChanges(newValue === "" ? "" : Number(newValue));
    }
  };

  const handleInputChangePersonChanges: ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const newIndex = event.target.value;

    if (/^\d*$/.test(newIndex)) {
      setPersonsChanges(newIndex === "" ? "" : Number(newIndex));
    }
  };

  return (
    <div>
      <div style={{ display: "flex" }}>
        <h4>Ihre Auswahl:</h4>
        {changesPlaned && (
          <h4 style={{ marginLeft: "13.4375em", position: "absolute" }}>
            Ihr Szenario:
          </h4>
        )}
      </div>
      <div className="OverviewNoBr mt-2.5">
        <div className="OverviewNoBr">
          <img
            src={buildingImages[selectedAnswers[0] as BuildingType]}
            alt=""
            className="ResultImgWidth"
          />
          <div className="inputWidth">
            <DropDown
              options={[
                { value: "oldBuilding", label: "Altbau" },
                { value: "newBuilding", label: "Neubau" },
                { value: "refurbished", label: "Saniert" },
              ]}
              selectedValue={selectedAnswers[0]}
              setSelectedValue={(value) =>
                setSelectedAnswers([
                  value,
                  selectedAnswers[1],
                  "",
                  selectedAnswers[3],
                ])
              }
              selectedAnswers={selectedAnswers}
              setSelectedAnswers={setSelectedAnswers}
              index={0}
            />
          </div>
        </div>
        {changesPlaned ? (
          <div className="OverviewNoBr">
            <span style={{ width: "51px" }} />
            <img
              src={buildingImages[selectedAnswersChanges[4] as BuildingType]}
              alt=""
              className="ResultImgWidth"
            />
            {/* <span>{translatedBuildingAgeChanges}</span> */}
            <div className="inputWidth">
              <DropDown
                options={[
                  { value: "oldBuilding", label: "Altbau" },
                  { value: "newBuilding", label: "Neubau" },
                  { value: "refurbished", label: "Saniert" },
                ]}
                selectedValue={selectedAnswersChanges[4]}
                setSelectedValue={(value) =>
                  setSelectedAnswersChanges([
                    "",
                    "",
                    "",
                    "",
                    value,
                    selectedAnswersChanges[5],
                  ])
                }
                selectedAnswers={selectedAnswersChanges}
                setSelectedAnswers={setSelectedAnswersChanges}
                index={4}
              />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="OverviewNoBr mt-2.5">
        <div className="OverviewNoBr">
          <img
            src={heatingImages[selectedAnswers[1] as HeatingType]}
            alt=""
            className="ResultImgWidth"
          />
          {/* <span>{translatedHeatingType}</span> */}
          <div className="inputWidth">
            <DropDown
              options={[
                { value: "biogasHeating", label: "Biogas" },
                { value: "electricHeating", label: "Elektroheizung" },
                { value: "districtHeating", label: "Fernwärme" },
                { value: "gas", label: "Gasheizung" },
                { value: "woodHeating", label: "Holzheizung" },
                { value: "pelletHeating", label: "Holzpellets" },
                { value: "woodChipHeating", label: "Holzschnitzel" },
                { value: "oil", label: "Ölheizung" },
                { value: "heatPump", label: "Wärmepumpe" },
              ]}
              selectedValue={selectedAnswers[1]}
              setSelectedValue={(value) =>
                setSelectedAnswers([
                  selectedAnswers[0],
                  value,
                  "",
                  selectedAnswers[3],
                ])
              }
              selectedAnswers={selectedAnswers}
              setSelectedAnswers={setSelectedAnswers}
              index={1}
            />
          </div>
        </div>
        {changesPlaned ? (
          <div className="OverviewNoBr">
            <span style={{ width: "51px" }} />
            <img
              src={heatingImages[selectedAnswersChanges[5] as HeatingType]}
              alt=""
              className="ResultImgWidth"
            />
            {/* <span>{translatedHeatingTypeChanges}</span> */}
            <div className="inputWidth">
              <DropDown
                options={[
                  { value: "biogasHeating", label: "Biogas" },
                  { value: "electricHeating", label: "Elektroheizung" },
                  { value: "districtHeating", label: "Fernwärme" },
                  { value: "gas", label: "Gasheizung" },
                  { value: "woodHeating", label: "Holzheizung" },
                  { value: "pelletHeating", label: "Holzpellets" },
                  { value: "woodChipHeating", label: "Holzschnitzel" },
                  { value: "oil", label: "Ölheizung" },
                  { value: "heatPump", label: "Wärmepumpe" },
                ]}
                selectedValue={selectedAnswersChanges[5]}
                setSelectedValue={(value) =>
                  setSelectedAnswersChanges([
                    "",
                    "",
                    "",
                    "",
                    selectedAnswersChanges[4],
                    value,
                  ])
                }
                selectedAnswers={selectedAnswersChanges}
                setSelectedAnswers={setSelectedAnswersChanges}
                index={5}
              />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="OverviewNoBr mt-2.5">
        <div className="OverviewNoBr">
          <img src={SqrMeters} alt="" className="ResultImgWidth" />
          {/* <span>{sqrMeter} m²</span> */}
          <div className="inputWidth">
            <InputField
              value={sqrMeter}
              setValue={setSqrMeter}
              onChange={handleInputChangeSqr}
              description="m²"
              maxValue={rangeMax}
              minValue={rangeMin}
            />
          </div>
        </div>
        {changesPlaned ? (
          <div className="OverviewNoBr">
            <span style={{ width: "51px" }} />
            <img src={SqrMeters} alt="" className="ResultImgWidth" />
            {/* <span>{sqrMeterChanges} m²</span> */}
            <div className="inputWidth">
              <InputField
                value={sqrMeterChanges}
                setValue={setSqrMeterChanges}
                onChange={handleInputChangeSqrChanges}
                description="m²"
                maxValue={rangeMax}
                minValue={rangeMin}
              />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="OverviewNoBr mt-2.5">
        <div className="OverviewNoBr">
          <img src={PersonOverview} alt="" className="ResultImgWidth" />
          {/* <span>{persons}</span> */}
          <div className="inputWidth">
            <InputField
              value={Number.isInteger(persons) ? Number(persons) : persons}
              setValue={setPersons}
              onChange={handleInputChangePerson}
              description="Personen"
              minValue={minPerson}
            />
          </div>
        </div>
        {changesPlaned ? (
          <div className="OverviewNoBr">
            <span style={{ width: "51px" }} />
            <img src={PersonOverview} alt="" className="ResultImgWidth" />
            {/* <span>{personsChanges}</span> */}
            <div className="inputWidth">
              <InputField
                value={
                  Number.isInteger(personsChanges)
                    ? Number(personsChanges)
                    : personsChanges
                }
                setValue={setPersonsChanges}
                onChange={handleInputChangePersonChanges}
                description="Personen"
                minValue={minPerson}
              />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      {!changesPlaned && (
        <span className="OverviewNoBr mt-2.5">
          <img src={Thumb} alt="" className="OverviewIMG" />
          keine Änderungen geplant
        </span>
      )}
      {!changesPlaned && (
        <button
          style={{
            marginLeft: "45px",
            marginTop: "10px",
            textDecoration: "underline",
          }}
          onClick={() => {
            setSqrMeterChanges(sqrMeter);
            setPersonsChanges(persons);
            setSelectedAnswersChanges([
              "",
              "",
              "",
              "",
              selectedAnswers[0],
              selectedAnswers[1],
            ]);
            setChangesPlaned(true);
          }}
        >
          Änderungen geplant?
        </button>
      )}
    </div>
  );
};

export default Overview;
