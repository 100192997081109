import React, { Dispatch, SetStateAction, useState } from "react";
import Footer from "./Footer";
import IconSelection from "./inputComponents/IconSelection";
import oldBuilding from "./../svg/age/oldBuilding.svg";
import refurbished from "./../svg/age/refurbished.svg";
import newBuilding from "./../svg/age/newBuilding.svg";

import oil from "./../svg/heating/oil.svg";
import gas from "./../svg/heating/gas.svg";
import electricHeating from "./../svg/heating/electricHeating.svg";
import districtHeating from "./../svg/heating/districtHeating.svg";
import heatPump from "./../svg/heating/heatPump.svg";
import woodHeating from "./../svg/heating/woodHeating.svg";
import woodChipHeating from "./../svg/heating/woodChipHeating.svg";
import pelletHeating from "./../svg/heating/pelletHeating.svg";
import biogasHeating from "./../svg/heating/biogasHeating.svg";

import SquareMeters from "./inputComponents/SquareMeters";
import Person from "./inputComponents/Person";

import planned from "./../svg/changesPlanned/planned.svg";
import notPlanned from "./../svg/changesPlanned/notPlanned.svg";

interface Props {
  pageNum: number;
  setChangesPlaned: Dispatch<SetStateAction<boolean>>;
  changesPlaned: boolean;
  setSelectedAnswers: Dispatch<SetStateAction<string[]>>;
  selectedAnswers: string[];
  setSqrMeter: Dispatch<SetStateAction<number | string>>;
  sqrMeter: number | string;
  setPersons: Dispatch<SetStateAction<number | string>>;
  persons: number | string;
}

const DataCollection = (props: Props) => {
  const {
    pageNum,
    setChangesPlaned,
    changesPlaned,
    setSelectedAnswers,
    selectedAnswers,
    setSqrMeter,
    sqrMeter,
    setPersons,
    persons,
  } = props;
  const handleSelect = (id: string, pageNum: number) => {
    const updatedAnswers = [...selectedAnswers];
    if (updatedAnswers[pageNum] === id) {
      updatedAnswers[pageNum] = "";
    } else {
      updatedAnswers[pageNum] = id;
      if (updatedAnswers[pageNum] === "planned") {
        setChangesPlaned(true);
      } else if (updatedAnswers[pageNum] === "notPlanned") {
        setChangesPlaned(false);
      }
    }

    setSelectedAnswers(updatedAnswers);
  };

  const page = [
    {
      title: "Zustand des Gebäudes",
      box: [
        { id: "oldBuilding", icon: oldBuilding, description: "Altbau" },
        { id: "refurbished", icon: refurbished, description: "Saniert" },
        { id: "newBuilding", icon: newBuilding, description: "Neubau" },
      ],
    },
    {
      title: "Welcher Heizungstyp wird in Ihrem Gebäude verwendet?",
      box: [
        { id: "oil", icon: oil, description: "Öl" },
        { id: "gas", icon: gas, description: "Gas" },
        {
          id: "electricHeating",
          icon: electricHeating,
          description: "Elektroheizung",
        },
        {
          id: "districtHeating",
          icon: districtHeating,
          description: "Fernwärme",
        },
        { id: "heatPump", icon: heatPump, description: "Wärmepumpe" },
        { id: "woodHeating", icon: woodHeating, description: "Holz" },
        {
          id: "woodChipHeating",
          icon: woodChipHeating,
          description: "Holzschnitzel",
        },
        { id: "pelletHeating", icon: pelletHeating, description: "Pellets" },
        { id: "biogasHeating", icon: biogasHeating, description: "Biogas" },
      ],
    },
  ];

  return (
    <div className="ScreenMargin">
      {pageNum < 3 && <h1>Wohnsituation heute</h1>}
      {page[pageNum]?.title ? (
        <h2 className="SquarePerson">{page[pageNum]?.title}</h2>
      ) : (
        ""
      )}
      {pageNum !== 2 && pageNum !== 6 ? (
        <div>
          {page[pageNum]?.box?.map((box, index) => (
            <div className="selectionBox" key={box.id} id={box.id}>
              <IconSelection
                id={box.id}
                icon={box.icon}
                description={box.description}
                onSelect={(id) => handleSelect(id, pageNum)}
                isSelected={selectedAnswers[pageNum] === box.id}
              />
            </div>
          ))}
        </div>
      ) : pageNum === 2 ? (
        <div className="SquarePerson">
          <SquareMeters setSqrMeter={setSqrMeter} sqrMeter={sqrMeter} />
          <Person setPersons={setPersons} persons={persons} />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default DataCollection;
