import React, { useEffect, useRef } from "react";
import { Scatter } from "react-chartjs-2";
import { Chart, ChartOptions, registerables, TooltipItem } from "chart.js";
import TableComponentScatterChart from "../inputComponents/TableComponentScatterChart";
Chart.register(...registerables);

const ScatterChart = () => {
  const chartRef = useRef<Chart<"scatter"> | null>(null);
  const pagewidth = window.innerWidth;

  const height = 20;
  const width = 30;

  const data = {
    datasets: [
      {
        label: "Massnahmen Besitzer:innen",
        data: [
          {
            x: 16, //10-20
            y: 12, //7-13
            text: "Smarte Thermostate installieren",
          },
          {
            x: 18, //10-20
            y: 9, //7-13
            text: "Ineffiziente Boiler austauschen \n(graue Emissionen sind \nvernachlässigbar)",
          },
          {
            x: 25, //20-30
            y: 11, //7-13
            text: "Photovoltaik installieren",
          },
          {
            x: 27, //20-30
            y: 16, //13-20
            text: "Fossile Heizungen austauschen",
          },
          {
            x: 23, //20-30
            y: 15, //13-20
            text: "Haus / Wohnung energetisch \nsanieren",
          },
          {
            x: 21, //20-30
            y: 12, //7-13
            text: "Smarte Heizsysteme installieren",
          },
        ],
        backgroundColor: "#322D60",
        pointRadius: 10,
      },
      {
        label: "Massnahmen Bewohner:innen",
        data: [
          {
            x: 5, //0-10
            y: 3, //0-7
            text: "Beleuchtung und Geräte abstellen, \nwenn nicht in Gebrauch (zB. WLAN \nwährend Ferien abstellen)",
          },
          {
            x: 6, //0-10
            y: 12, //7-13
            text: "Heizung in unbenutzten Räumen \nabstellen",
          },
          {
            x: 3, //0-10
            y: 5, //0-7
            text: "Waschmaschine und Geschirrspüler \nauf tiefst möglichen Temperaturen \nlaufen lassen",
          },
          {
            x: 7, //0-10
            y: 2, //0-7
            text: "Wäsche aufhängen statt tumblern",
          },
          {
            x: 8, //0-10
            y: 12, //7-14
            text: "Statt Heizung auf 24° stellen, \nlieber Pulli und Socken \nanziehen",
          },
          {
            x: 4, //0-10
            y: 11, //7-13
            text: "Nur kurz duschen, heisses Bad \nals Luxus betrachten",
          },
          {
            x: 6, //0-10
            y: 4, //0-7
            text: "Energieeffiziente Geräte benutzen",
          },
          {
            x: 4, //0-10
            y: 6, //0-7
            text: "Korrektes Lüften",
          },
          {
            x: 12, //10-20
            y: 8, //7-13
            text: "LED-Beleuchtung installieren",
          },
          {
            x: 13, //10-20
            y: 9, //7-13
            text: "Erneuerbaren Strom beziehen",
          },
          {
            x: 28, //20-30
            y: 17, //13-20
            text: "Beim nächsten Umzug nicht nur auf \nHeizträger sondern auch auf \nWohnfläche achten",
          },
          {
            x: 29, //20-30
            y: 19, //13-20
            text: "Der Verwaltung melden, dass man \ninnerhalb der Liegenschaft in eine \nkleinere Wohnung ziehen möchte",
          },
          {
            x: 25, //20-30
            y: 18, //13-20
            text: "Wohnungstausch vereinbaren oder \nWG’s gründen",
          },
        ],
        backgroundColor: "#EA4E44",
        pointRadius: 10,
      },
    ],
  };

  const options: any = {
    scales: {
      x: {
        min: 0,
        max: width,
        border: {
          display: false,
        },
        ticks: {
          padding: 20,
          color: "#332F62",
          callback: function (tickValue: number | string) {
            return tickValue === 0
              ? "klein"
              : tickValue === width / 2
              ? "AUFWAND"
              : tickValue === width
              ? "hoch"
              : "";
          },
        },
        grid: {
          color: function (context: { tick: { value: number } }) {
            if (context.tick.value === width / 2) {
              return "#CCCCCC";
            }
          },
        },
      },
      y: {
        min: 0,
        max: height,
        border: {
          display: false,
        },
        ticks: {
          padding: 20,
          color: "#332F62",
          callback: function (tickValue: number | string) {
            return tickValue === 0
              ? "klein"
              : tickValue === height / 2
              ? "NUTZEN"
              : tickValue === height
              ? "hoch"
              : "";
          },
        },
        grid: {
          color: function (context: { tick: { value: number } }) {
            if (context.tick.value === height / 2) {
              return "#CCCCCC";
            }
          },
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        align: "end",
        labels: {
          color: "#332F62",
          usePointStyle: true,
          font: {
            family: "Suisse Int'l",
            size: 16,
          },
        },
      },
      tooltip: {
        displayColors: false,
        backgroundColor: "#fff",
        bodyColor: "#000",
        borderColor: "#322d60",
        padding: 10,
        borderWidth: 1,
        cornerRadius: 0,
        callbacks: {
          label: function (tooltipItem: any) {
            const label =
              tooltipItem.dataset.data[tooltipItem.dataIndex].text.split("\n");
            return label;
          },
        },
      },
    },
  };

  const updateChartPointRadius = () => {
    const width = window.innerWidth;
    const chartInstance = chartRef.current;
    if (chartInstance) {
      chartInstance.data.datasets.forEach((dataset) => {
        dataset.pointRadius = width < 760 ? 4 : 10;
      });
      chartInstance.update();
    }
  };

  useEffect(() => {
    updateChartPointRadius();
    window.addEventListener("resize", updateChartPointRadius);
    return () => {
      window.removeEventListener("resize", updateChartPointRadius);
    };
  }, []);

  return (
    <>
      {window.innerWidth > 1100 ? (
        <div style={{textAlign:"center"}}>
          <p style={{marginTop: "2em", marginBottom: "2em"}}>
          Fahren Sie mit der Maus über die Punkte, um die verschiedenen
          Massnahmen anzuzeigen.</p>
          <div className="scatterChartWidth">
            <div style={{ width: "68.75em" }}>
              <Scatter ref={chartRef} data={data} options={options} />
            </div>
          </div>
        </div>
      ) : (
        <div className="overflow-x-auto">
          <br />
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Zielgruppe
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Massnahme
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              <TableComponentScatterChart data={data} />
            </tbody>
            <br />
            <br />
          </table>
        </div>
      )}
    </>
  );
};

export default ScatterChart;
