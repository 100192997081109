import React, { Dispatch, SetStateAction } from "react";
import previous from "../../svg/navigation/previous.svg";

interface Props {
  pageNum: number;
  setPageNum: Dispatch<SetStateAction<number>>;
  prevText: string;
  changesPlaned: boolean;
}

const Previous = (props: Props) => {
  const { pageNum, setPageNum, prevText, changesPlaned } = props;

  const PreviousPage = () => {
    if (pageNum >= 3) {
      setPageNum(2);
    } else {
      setPageNum(pageNum != 0 ? pageNum - 1 : 0);
    }
  };

  return (
    <button className="previous-button" onClick={PreviousPage}>
      <img src={previous} alt="Next Icon" className="previous-icon" />
      <p>{prevText}</p>
    </button>
  );
};

export default Previous;
