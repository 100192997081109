import React, {
  useState,
  ChangeEventHandler,
  useEffect,
  useRef,
  ChangeEvent,
  Dispatch,
  SetStateAction,
} from "react";
import InputField from "./InputField";

interface Props {
  setSqrMeter: Dispatch<SetStateAction<number | string>>;
  sqrMeter: number | string;
}

const SquareMeters = (props: Props) => {
  const { setSqrMeter, sqrMeter } = props;
  const rangeRef = useRef<HTMLInputElement>(null);
  const rangeVRef = useRef<HTMLDivElement>(null);

  const rangeMax = 1000;
  const rangeMin = 20;
  useEffect(() => {
    const range = rangeRef.current;
    const rangeV = rangeVRef.current;
    const updateValue = () => {
      if (range && rangeV) {
        const newValue = Number(
          ((Number(range.value) - Number(range.min)) * 100) /
            (Number(range.max) - Number(range.min))
        );
        const newPosition = 10 - newValue * 0.2;
        rangeV.innerHTML = `<span>${range.value}</span>`;
        rangeV.style.left = `calc(${newValue}% + (${newPosition}px))`;
        setSqrMeter(range.valueAsNumber);
      }
    };

    updateValue();

    const inputListener = () => {
      updateValue();
    };

    range?.addEventListener("input", inputListener);

    return () => {
      range?.removeEventListener("input", inputListener);
    };
  }, []);

  const handleSliderChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.target.value, 10);
    setSqrMeter(newValue);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;

    if (/^\d*$/.test(newValue)) {
      setSqrMeter(newValue === "" ? "" : Number(newValue));
    }
  };

  const rangeValue = Math.min(Math.max(Number(sqrMeter), rangeMin), rangeMax);

  return (
    <div className="Slidercontainer">
      <h2 className="relative mb-10">Grösse der Wohnung / EFH</h2>
      <div className="range-wrap">
        <div
          className="range-value"
          style={{
            left: `calc(${
              ((rangeValue - rangeMin) / (rangeMax - rangeMin)) * 100
            }% + (10px - ${
              ((rangeValue - rangeMin) / (rangeMax - rangeMin)) * 20
            }px))`,
          }}
        >
          <span>{rangeValue}</span>
        </div>
        <input
          ref={rangeRef}
          type="range"
          min={rangeMin}
          max={rangeMax}
          value={rangeValue}
          className="slider"
          onChange={handleSliderChange}
        />
      </div>
      <div className="mt-10 TextInput">
        <InputField
          value={sqrMeter}
          setValue={setSqrMeter}
          onChange={handleInputChange}
          description="m²"
          maxValue={rangeMax}
          minValue={rangeMin}
        />
      </div>
    </div>
  );
};

export default SquareMeters;
