import React, { Dispatch, SetStateAction } from "react";
import next from "../../svg/navigation/next.svg";

interface Props {
  pageNum: number;
  setPageNum: Dispatch<SetStateAction<number>>;
  nextText: string;
  answered?: boolean;
}

const Next = (props: Props) => {
  const { pageNum, setPageNum, nextText, answered } = props;

  const NextPage = () => {
    if (answered === true || answered === undefined) {
      setPageNum(pageNum + 1);
    }
  };

  return (
    <button
      className="next-button woocommerce-Button button"
      onClick={NextPage}
      disabled={!answered}
    >
      <p>{nextText}</p>
      <img src={next} alt="Next Icon" className="next-icon" />
    </button>
  );
};

export default Next;
