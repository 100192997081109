import React from "react";

interface DataPoint {
  x: number;
  y: number;
  text: string;
}

interface Dataset {
  label: string;
  data: DataPoint[];
  backgroundColor: string;
  pointRadius: number;
}

interface ChartData {
  datasets: Dataset[];
}

const TableComponentScatterChart: React.FC<{ data: ChartData }> = ({
  data,
}) => {
  const categorizeData = (data: ChartData) => {
    const categories: { [key: string]: { label: string; text: string }[] } = {
      "Kleiner Aufwand, kleiner Nutzen": [],
      "Grosser Nutzen, kleiner Aufwand": [],
      "Grosser Aufwand, grosser Nutzen": [],
      "Grosser Aufwand, kleiner Nutzen": [],
    };

    data.datasets.forEach((dataset) => {
      dataset.data.forEach((point) => {
        if (point.y < 10 && point.x < 15) {
          categories["Kleiner Aufwand, kleiner Nutzen"].push({
            label: dataset.label,
            text: point.text,
          });
        } else if (point.y >= 10 && point.x < 15) {
          categories["Grosser Nutzen, kleiner Aufwand"].push({
            label: dataset.label,
            text: point.text,
          });
        } else if (point.y >= 10 && point.x >= 15) {
          categories["Grosser Aufwand, grosser Nutzen"].push({
            label: dataset.label,
            text: point.text,
          });
        } else if (point.y < 10 && point.x >= 15) {
          categories["Grosser Aufwand, kleiner Nutzen"].push({
            label: dataset.label,
            text: point.text,
          });
        }
      });
    });

    return categories;
  };

  const categorizedData = categorizeData(data);

  return (
    <>
      {Object.keys(categorizedData).map((category, index) => (
        <React.Fragment key={category}>
          <tr key={`category-${category}`} className="divide-x bg-gray-200">
            <td
              colSpan={2}
              className="px-4 py-2 text-sm font-semibold text-[#322d60]"
            >
              {category}
            </td>
          </tr>
          {categorizedData[category].map((point, pointIndex) => (
            <tr key={`${category}-${pointIndex}`} className="divide-x">
              <td className="px-4 py-2 whitespace-nowrap text-sm text-[#322d60]">
                {point.label}
              </td>
              <td className="px-4 py-2 text-sm text-[#322d60]">{point.text}</td>
            </tr>
          ))}
        </React.Fragment>
      ))}
    </>
  );
};

export default TableComponentScatterChart;
