import React, { Dispatch, SetStateAction } from "react";
import BarChart from "./results/BarChart";
import ScatterChart from "./results/ScatterChart";

interface Props {
  pageNum: number;
  setPageNum: Dispatch<SetStateAction<number>>;
  changesPlaned: boolean;
  CO2Person: number;
  CO2PersonChanges: number;

  selectedAnswers: string[];
  sqrMeter: string | number;
  persons: string | number;
  selectedAnswersChanges: string[];
  sqrMeterChanges: string | number;
  personsChanges: string | number;
  setSelectedAnswers: Dispatch<SetStateAction<string[]>>;
  setSqrMeter: Dispatch<SetStateAction<number | string>>;
  setPersons: Dispatch<SetStateAction<number | string>>;
  setSelectedAnswersChanges: Dispatch<SetStateAction<string[]>>;
  setSqrMeterChanges: Dispatch<SetStateAction<number | string>>;
  setPersonsChanges: Dispatch<SetStateAction<number | string>>;
  setChangesPlaned: Dispatch<SetStateAction<boolean>>;
}

const Result = (props: Props) => {
  const {
    pageNum,
    setPageNum,
    changesPlaned,
    CO2Person,
    CO2PersonChanges,
    selectedAnswers,
    sqrMeter,
    persons,
    selectedAnswersChanges,
    sqrMeterChanges,
    personsChanges,
    setSelectedAnswers,
    setSqrMeter,
    setPersons,
    setSelectedAnswersChanges,
    setSqrMeterChanges,
    setPersonsChanges,
    setChangesPlaned
  } = props;

  return (
    <div className="ScreenMargin">
      <h1 id="resultTitle">Resultate</h1>
      <div className="ResultSolution">
        {pageNum === 5 ? (
          <>
            <h3 onClick={() => setPageNum(3)}>Einordnung der Emissionen</h3>
            <h3 onClick={() => setPageNum(4)}>Emissionen und Budget</h3>
            <h3 className="underlined">Tipps</h3>
          </>
        ) : pageNum === 4 ? (
          <>
            <h3 onClick={() => setPageNum(3)}>Einordnung der Emissionen</h3>
            <h3 className="underlined">Emissionen und Budget</h3>
            <h3 onClick={() => setPageNum(5)}>Tipps</h3>
          </>
        ) : pageNum === 3 ? (
          <>
            <h3 className="underlined">Einordnung der Emissionen</h3>
            <h3 onClick={() => setPageNum(4)}>Emissionen und Budget</h3>
            <h3 onClick={() => setPageNum(5)}>Tipps</h3>
          </>
        ) : (
          ""
        )}
      </div>
      {pageNum === 3 ? (
        <BarChart
          CO2PersonChanges={CO2PersonChanges}
          CO2Person={CO2Person}
          pageNum={pageNum}
          changesPlaned={changesPlaned}
          selectedAnswers={selectedAnswers}
          sqrMeter={sqrMeter}
          persons={persons}
          selectedAnswersChanges={selectedAnswersChanges}
          sqrMeterChanges={sqrMeterChanges}
          personsChanges={personsChanges}
          setSelectedAnswers={setSelectedAnswers}
          setSqrMeter={setSqrMeter}
          setPersons={setPersons}
          setSelectedAnswersChanges={setSelectedAnswersChanges}
          setSqrMeterChanges={setSqrMeterChanges}
          setPersonsChanges={setPersonsChanges}
          setChangesPlaned={setChangesPlaned}
        />
      ) : pageNum === 4 ? (
        <BarChart
          CO2PersonChanges={CO2PersonChanges}
          CO2Person={CO2Person}
          pageNum={pageNum}
          changesPlaned={changesPlaned}
          selectedAnswers={selectedAnswers}
          sqrMeter={sqrMeter}
          persons={persons}
          selectedAnswersChanges={selectedAnswersChanges}
          sqrMeterChanges={sqrMeterChanges}
          personsChanges={personsChanges}
          setSelectedAnswers={setSelectedAnswers}
          setSqrMeter={setSqrMeter}
          setPersons={setPersons}
          setSelectedAnswersChanges={setSelectedAnswersChanges}
          setSqrMeterChanges={setSqrMeterChanges}
          setPersonsChanges={setPersonsChanges}
          setChangesPlaned={setChangesPlaned}

        />
      ) : pageNum === 5 ? (
        <ScatterChart />
      ) : (
        ""
      )}
    </div>
  );
};

export default Result;
