import React, { useEffect, useState } from "react";
import "./App.css";
import DataCollection from "./components/DataCollection";
import Footer from "./components/Footer";
import Result from "./components/Result";

type BuildingType = "oldBuilding" | "newBuilding" | "refurbished";
type HeatingType =
  | "biogasHeating"
  | "electricHeating"
  | "districtHeating"
  | "gas"
  | "woodHeating"
  | "pelletHeating"
  | "woodChipHeating"
  | "oil"
  | "heatPump";

interface CO2Data {
  oldBuilding: Record<HeatingType, number>;
  refurbished: Record<HeatingType, number>;
  newBuilding: Record<HeatingType, number>;
}

function App() {
  const [pageNum, setPageNum] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState<string[]>([
    "",
    "",
    "",
    "",
  ]);

  const [sqrMeter, setSqrMeter] = useState<number | string>(80);
  const [persons, setPersons] = useState<number | string>(1);
  const [selectedAnswersChanges, setSelectedAnswersChanges] = useState<
    string[]
  >(["", "", "", "", "", ""]);
  const [sqrMeterChanges, setSqrMeterChanges] = useState<number | string>(80);
  const [personsChanges, setPersonsChanges] = useState<number | string>(1);

  const [changesPlaned, setChangesPlaned] = useState<boolean>(false);
  const [answered, setAnswered] = useState<boolean>(false);

  const co2Data: CO2Data = {
    oldBuilding: {
      biogasHeating: 55.3047143198418,
      electricHeating: 13.2202853131771,
      districtHeating: 27.5599447615591,
      gas: 101.545548820826,
      woodHeating: 12.1582722614949,
      pelletHeating: 14.4430096072022,
      woodChipHeating: 6.69435963387599,
      oil: 148.887672763492,
      heatPump: 5.41201989297653,
    },
    newBuilding: {
      biogasHeating: 8.63470247479074,
      electricHeating: 2.60036658839364,
      districtHeating: 4.63515288728796,
      gas: 15.3074834549705,
      woodHeating: 2.3990284306135,
      pelletHeating: 2.66604016447415,
      woodChipHeating: 1.61508052058584,
      oil: 22.1189803980455,
      heatPump: 1.16340019002346,
    },
    refurbished: {
      biogasHeating: 20.8644209587405,
      electricHeating: 5.35475454677101,
      districtHeating: 10.639448666547,
      gas: 37.9058763057471,
      woodHeating: 4.96336351342372,
      pelletHeating: 5.80537360854174,
      woodChipHeating: 2.94970990902062,
      oil: 55.3531974864288,
      heatPump: 2.47712035327276,
    },
  };
  useEffect(() => {
    let currentPageFilled = false;
    if (pageNum < 2 || pageNum === 3) {
      currentPageFilled = selectedAnswers[pageNum] !== "";
    } else if (pageNum === 2) {
      currentPageFilled = persons > 0;
    } else if ((changesPlaned && pageNum === 4) || pageNum === 5) {
      currentPageFilled = selectedAnswersChanges[pageNum] !== "";
    } else if (changesPlaned && pageNum === 6) {
      currentPageFilled = personsChanges > 0;
    } else {
      currentPageFilled = true;
    }
    setAnswered(currentPageFilled);
  }, [
    pageNum,
    selectedAnswers,
    selectedAnswersChanges,
    persons,
    personsChanges,
  ]);

  const calculateCO2 = (
    buildingType: BuildingType,
    heatingType: HeatingType,
    sqrMeters: string | number,
    person: string | number
  ): number => {
    if (
      co2Data[buildingType] &&
      co2Data[buildingType][heatingType] &&
      Number(sqrMeters) &&
      Number(person)
    ) {
      return (
        (co2Data[buildingType][heatingType] * Number(sqrMeters)) /
        Number(person)
      );
    } else {
      return 0;
    }
  };

  return (
    <div className="App">
      {pageNum < 3 ? (
        <DataCollection
          pageNum={pageNum}
          changesPlaned={changesPlaned}
          setSelectedAnswers={setSelectedAnswers}
          selectedAnswers={selectedAnswers}
          setSqrMeter={setSqrMeter}
          sqrMeter={sqrMeter}
          setPersons={setPersons}
          persons={persons}
          setChangesPlaned={setChangesPlaned}
        />
      ) : (
        <Result
          pageNum={pageNum}
          setPageNum={setPageNum}
          changesPlaned={changesPlaned}
          CO2Person={calculateCO2(
            selectedAnswers[0] as BuildingType,
            selectedAnswers[1] as HeatingType,
            sqrMeter,
            persons
          )}
          CO2PersonChanges={calculateCO2(
            selectedAnswersChanges[4] as BuildingType,
            selectedAnswersChanges[5] as HeatingType,
            sqrMeterChanges,
            personsChanges
          )}
          selectedAnswers={selectedAnswers}
          sqrMeter={sqrMeter}
          persons={persons}
          selectedAnswersChanges={selectedAnswersChanges}
          sqrMeterChanges={sqrMeterChanges}
          personsChanges={personsChanges}
          setSelectedAnswers={setSelectedAnswers}
          setSqrMeter={setSqrMeter}
          setPersons={setPersons}
          setSelectedAnswersChanges={setSelectedAnswersChanges}
          setSqrMeterChanges={setSqrMeterChanges}
          setPersonsChanges={setPersonsChanges}
          setChangesPlaned={setChangesPlaned}
        />
      )}
      {pageNum === 3 ? (
        <Footer
          pageNum={pageNum}
          setPageNum={setPageNum}
          nextText="emissionen und budget"
          prevText="zurück zu datenerfassung"
          answered={true}
          changesPlaned={changesPlaned}
        />
      ) : pageNum === 4 ? (
        <Footer
          pageNum={pageNum}
          setPageNum={setPageNum}
          nextText="Tipps"
          prevText="zurück zu datenerfassung"
          answered={true}
          changesPlaned={changesPlaned}
        />
      ) : pageNum === 5 ? (
        <Footer
          pageNum={pageNum}
          setPageNum={setPageNum}
          prevText="zurück zu datenerfassung"
          answered={true}
          changesPlaned={changesPlaned}
        />
      ) : (
        <Footer
          pageNum={pageNum}
          setPageNum={setPageNum}
          nextText="nächster Schritt"
          prevText="vorheriger Schritt"
          answered={answered}
          changesPlaned={changesPlaned}
        />
      )}
    </div>
  );
}

export default App;
