import React from "react";

interface ChartData {
  label?: string;
  value?: number;
  text1?: string;
  quelle?: string;
  co2Labling?: string;
  color?: string;
}

interface TableComponentProps {
  data: ChartData;
}

const TableComponent: React.FC<TableComponentProps> = ({ data }) => {
  return (
    <tr
      className="divide-x"
      style={{
        background: `${
          !data.text1 && !data.value && !data.label ? "rgb(229, 231, 235)" : ""
        }`,
      }}
    >
      <td className="px-4 py-2 text-sm font-medium text-[#322d60] vertical-line">
        {data.label}
      </td>
      <td className="px-4 py-2 whitespace-nowrap text-sm text-[#322d60]">
        {data.value} {data.co2Labling}
      </td>
      <td className="px-4 py-2  text-sm text-[#322d60]">{data.text1}</td>
      <td className="px-4 py-2  text-sm text-[#322d60]">{data.quelle}</td>
    </tr>
  );
};

export default TableComponent;
