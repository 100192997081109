import React from "react";
import hook from "./../../svg/hook.svg";

interface Props {
  icon: string;
  description: string;
  id: string;
  onSelect: (id: string) => void;
  isSelected: boolean;
}

const IconSelection: React.FC<Props> = ({
  icon,
  description,
  id,
  onSelect,
  isSelected,
}) => {
  const handleClick = () => {
    onSelect(id);
  };

  return (
    <div>
      <div
        className={`box ${isSelected ? "selected" : ""}`}
        onClick={handleClick}
      >
        {isSelected && <img src={hook} className="hook" alt="hook" />}
        <img src={icon} className="houseIMG" alt="house" />
      </div>
      <p className="iconDescription">{description}</p>
    </div>
  );
};

export default IconSelection;
