import React, { Dispatch, SetStateAction } from "react";
import Previous from "./navigation/Previous";
import Next from "./navigation/Next";

interface Props {
  pageNum: number;
  setPageNum: Dispatch<SetStateAction<number>>;
  nextText?: string;
  prevText: string;
  answered?: boolean;
  changesPlaned: boolean;
}

const Footer = (props: Props) => {
  const { pageNum, setPageNum, nextText, prevText, answered, changesPlaned } =
    props;

  return (
    <div className="footer">
      {pageNum === 0 ? (
        <div></div>
      ) : (
        <Previous
          pageNum={pageNum}
          setPageNum={setPageNum}
          prevText={prevText}
          changesPlaned={changesPlaned}
        />
      )}

      {nextText ? (
        <Next
          pageNum={pageNum}
          setPageNum={setPageNum}
          nextText={nextText}
          answered={answered}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default Footer;
