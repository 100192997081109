import React, { Dispatch, SetStateAction } from "react";
import { Bar } from "react-chartjs-2";
import { Chart, ChartOptions, registerables, TooltipItem } from "chart.js";
import TableComponentBarChart from "../inputComponents/TableComponentBarChart";
import Overview from "../Overview";

interface Props {
  CO2PersonChanges?: number;
  CO2Person: number;
  pageNum: number;
  changesPlaned: boolean;

  selectedAnswers: string[];
  sqrMeter: string | number;
  persons: string | number;
  selectedAnswersChanges: string[];
  sqrMeterChanges: string | number;
  personsChanges: string | number;
  setSelectedAnswers: Dispatch<SetStateAction<string[]>>;
  setSqrMeter: Dispatch<SetStateAction<number | string>>;
  setPersons: Dispatch<SetStateAction<number | string>>;
  setSelectedAnswersChanges: Dispatch<SetStateAction<string[]>>;
  setSqrMeterChanges: Dispatch<SetStateAction<number | string>>;
  setPersonsChanges: Dispatch<SetStateAction<number | string>>;
  setChangesPlaned: Dispatch<SetStateAction<boolean>>;
}
Chart.register(...registerables);

const BarChart = (props: Props) => {
  const {
    CO2Person,
    CO2PersonChanges,
    pageNum,
    changesPlaned,
    selectedAnswers,
    sqrMeter,
    persons,
    selectedAnswersChanges,
    sqrMeterChanges,
    personsChanges,
    setSelectedAnswers,
    setSqrMeter,
    setPersons,
    setSelectedAnswersChanges,
    setSqrMeterChanges,
    setPersonsChanges,
    setChangesPlaned,
  } = props;

  const chartData = [
    {
      label: "Aktuelle Emissionen Heizen",
      value: Number(Math.round(CO2Person)),
      text1:
        "Berechnung der betrieblichen Emissionen aus \nHeizen, Warmwasser, Belichtung und \nHaushaltsgeräte pro Person pro Jahr in kg \nCO2-Äquivalente.\n",
      quelle: "Wüest Partner CO2-Rechner",
      co2Labling: "kgCO₂eq/P a",
      color: "#332F62",
    },
    ...(CO2PersonChanges && CO2PersonChanges !== 0 && changesPlaned
      ? [
          {
            label: "Szenario Emissionen Heizen",
            value: Number(Math.round(CO2PersonChanges)),
            text1:
              "Berechnung der betrieblichen Emissionen aus \nHeizen, Warmwasser, Belichtung und \nHaushaltsgeräte pro Person pro Jahr in kg \nCO2-Äquivalente.\n",
            quelle: "Wüest Partner CO2-Rechner",
            co2Labling: "kgCO₂eq/P a",
            color: "#806DAE",
          },
        ]
      : []),

    ...(pageNum === 3
      ? [
          {},
          {
            label: "Während einem Jahr mit dem Auto 20km pendeln",
            value: 1400,
            text1:
              "Berechnung mit dem myclimate-Autorecher, \nwie folgt: 200 Tage im Jahr 20km-Distanz, \nBenziner (Ø Verbrauch 8,42l/100km).\n",
            quelle: "myclimate, \nhttps://co2.myclimate.org",
            co2Labling: "kgCO₂eq/P a",
            color: "#ea4e44",
          },
          {
            label: "Während einem Jahr mit dem Zug 100km pendeln",
            value: 320,
            text1: "Berechnung mit dem Mobitool im \nOnlinefahrplan der SBB\n",
            quelle: "SBB / Mobitool",
            co2Labling: "kgCO₂eq/P a",
            color: "#ea4e44",
          },
          {
            label: "Flug Zürich/Wien hin-retour 1 Person",
            value: 404,
            text1: "Berechnung mit dem myclimate-Flugrechner.\n",
            quelle: "myclimate, \nhttps://co2.myclimate.org",
            co2Labling: "kgCO₂eq/P",
            color: "#ea4e44",
          },
          {
            label: "Flug Zürich/Bali hin-retour 1 Person",
            value: 4000,
            text1: "Berechnung mit dem myclimate-Flugrechner.\n",
            quelle: "myclimate, \nhttps://co2.myclimate.org",
            co2Labling: "kgCO₂eq/P",
            color: "#ea4e44",
          },
          {
            label: "7-tägige Kreuzfahrt",
            value: 1900,
            text1: "Berechnung mit dem myclimate- \nKreuzfahrtrechner.\n",
            quelle: "myclimate, \nhttps://co2.myclimate.org",
            co2Labling: "kgCO₂eq/P",
            color: "#ea4e44",
          },
          {
            label: "Zugreise Zürich-Wien",
            value: 17,
            text1: "Berechnung mit dem Mobitool im \nOnlinefahrplan der SBB.\n",
            quelle: "SBB / Mobitool",
            co2Labling: "kgCO₂eq/P",
            color: "#ea4e44",
          },
          {
            label: "Durchschnittliche Jahresernährung einer Person",
            value: 1837,
            text1:
              "Umweltbelastung pro Jahr und Person gemäss der \nim Jahr 2012 für den Schweizer Markt \nproduzierten Nahrungsmittel und Getränke.\n",
            quelle:
              "Niels Jungbluth, Simon Eggenberger, \nRegula Keller (2015) Ökoprofil von \nErnährungsstilen. ESU-services GmbH im Auftrag \ndes WWF Schweiz, Zürich.",
            co2Labling: "kgCO₂eq/P a",
            color: "#ea4e44",
          },
          {
            label: "Durchschnittliche Jahresernährung einer Person (vegan)",
            value: 1124,
            text1: "Veganer (keinerlei tierische Produkte).\n",
            quelle:
              "Niels Jungbluth, Simon Eggenberger, \nRegula Keller (2015) Ökoprofil von \nErnährungsstilen. ESU-services GmbH im Auftrag \ndes WWF Schweiz, Zürich.",
            co2Labling: "kgCO₂eq/P a",
            color: "#ea4e44",
          },
          {
            label:
              "Durchschnittliche Jahresernährung einer Person (fleischlastig)",
            value: 2324,
            text1: "Fleischvernichter: 2kg Fleisch pro Woche, 6 Eier.\n",
            quelle:
              "Niels Jungbluth, Simon Eggenberger, \nRegula Keller (2015) Ökoprofil von \nErnährungsstilen. ESU-services GmbH im Auftrag \ndes WWF Schweiz, Zürich.",
            co2Labling: "kgCO₂eq/P a",
            color: "#ea4e44",
          },
          {
            label:
              "Durchschnittliche Jahresernährung einer Person (vegetarisch)",
            value: 1380,
            text1:
              "Ovo-Lacto-Vegetarier (nur pflanzliche \nNahrungsmittel, Eier, Honig und Milchprodukte).\n",
            quelle:
              "Niels Jungbluth, Simon Eggenberger, \nRegula Keller (2015) Ökoprofil von \nErnährungsstilen. ESU-services GmbH im Auftrag \ndes WWF Schweiz, Zürich.",
            co2Labling: "kgCO₂eq/P a",
            color: "#ea4e44",
          },
          {
            label: "Neuer Laptop",
            value: 326,
            text1: "13-Zoll MacBook Pro 128GB Speicher.\n",
            quelle:
              "Berners-Lee, M. (2020). How bad \nare bananas?: the carbon footprint \nof everything. Profile Books.",
            co2Labling: "kgCO₂eq/P",
            color: "#ea4e44",
          },
          {
            label: "Haustier pro Jahr (Hund)",
            value: 950,
            text1:
              "Umweltbelastung pro Jahr eines \nHundes bestehend aus Nahrung, Autofahrten \nzu Freizeitzwecken, Ausstattung, Pflege usw.\n",
            quelle:
              "Annaheim, J., Jungbluth, \nN., & Meili, C. (2018). Ökobilanz von \nHaus-und Heimtieren. ESU-service \nGmbH-Praktikumsarbeit, Schaffhausen, \nSchweiz.",
            co2Labling: "kgCO₂eq/Hund a",
            color: "#ea4e44",
          },
        ]
      : pageNum === 4
      ? [
          {},
          {
            label: "Treibhausgas-Budget pro Jahr pro Kopf BAFU",
            value: 600,
            text1:
              'THG-Budget, welches eine Person \npro Jahr zu Gute hat. Gemäss BAFU: \n"Der von der planetaren \nBelastbarkeitsgrenze ableitbare \nSchwellenwert liegt bei unter 0.6 t CO2-\nÄquivalenten pro Kopf und Jahr. Mit jedem \nJahr, während dem der Treibhausgas-\nFussabdruck pro Kopf über diesem Wert \nliegt, sinkt dieser Schwellenwert – \nund der Handlungsbedarf steigt."\n',
            quelle:
              "BAFU, \nhttps://www.bafu.admin.ch/bafu/\nde/home/themen/klima/inkuerze.html",
            co2Labling: "kgCO₂eq/P a",
            color: "#ea4e44",
          },
          {
            label: "THG-Budget pro Kopf pro Jahr gemäss KlimaSeniorinnen",
            value: 470,
            text1:
              "Restbudget der Schweiz zwischen \n2022 und 2100 gemäss 1.5°C Ziel unter \nBerücksichtigung historischer Emissionen \nauf alle Einwohner:innen gleich verteilt.\n",
            quelle:
              "myclimate, \nhttps://www.myclimate.org/de-ch/\ninformieren/faq/faq-detail/was-ist-ein-co2-budget/",
            co2Labling: "kgCO₂eq/P a",
            color: "#ea4e44",
          },
          {
            label: "THG-Ausstoss Schweiz 2022 pro Kopf",
            value: 5000,
            text1:
              "Treibhausgasemissionen in, die \nim Jahr 2022 auf Schweizer Boden \nangefallen sind, berechnet \npro Kopf\n",
            quelle:
              "BAFU, \nhttps://www.bafu.admin.ch/bafu/\nde/home/themen/klima/inkuerze.html",
            co2Labling: "kgCO₂eq/P a",
            color: "#ea4e44",
          },
          {
            label: "THG-Ausstoss Schweiz 2021 inkl. Importe pro Kopf",
            value: 13000,
            text1:
              "Treibhausgasemissionen in, die im \nJahr 2021 auf Schweizer Boden angefallen \nsind sowie solche, die bei der Produktion \nvon importierten Gütern angefallen sind, \nberechnet pro Kopf\n",
            quelle:
              "BAFU, \nhttps://www.bafu.admin.ch/bafu/\nde/home/themen/klima/inkuerze.html",
            co2Labling: "kgCO₂eq/P a",
            color: "#ea4e44",
          },
        ]
      : []),
  ];

  const data = {
    labels: chartData.map((item) => item.label),
    datasets: [
      {
        data: chartData.map((item) => item.value),
        backgroundColor: chartData.map((item) => item.color),
        borderWidth: 1,
        text1: chartData.map((item) => item.text1),
        quelle: chartData.map((item) => item.quelle),
        co2Labling: chartData.map((item) => item.co2Labling),
      },
    ],
  };

  const options: any = {
    indexAxis: "y",
    locale: "de-CH",
    scales: {
      y: {
        ticks: {
          font: {
            size: "11",
            weight: "bold",
          },
        },
      },
    },

    plugins: {
      legend: {
        labels: {
          weight: "bold",
        },
        display: false,
      },
      tooltip: {
        bodyFont: {
          family: "Suisse Int'l",
          weight: "normal",
        },
        weight: "normal",
        xAlign: "left",
        displayColors: false,
        backgroundColor: "#fff",
        bodyColor: "#000",
        borderColor: "#322d60",
        padding: 10,
        titleColor: "#000000",
        borderWidth: 1,
        cornerRadius: 0,
        callbacks: {
          title: function (tooltipItems: any) {
            return `${tooltipItems[0].formattedValue} ${
              tooltipItems[0].dataset.co2Labling[tooltipItems[0].dataIndex]
            }`;
          },
          label: function (tooltipItem: any) {
            return tooltipItem.dataset.text1[tooltipItem.dataIndex].split("\n");
          },
          afterBody: function (tooltipItem: any) {
            return `Quelle: ${
              tooltipItem[0].dataset.quelle[tooltipItem[0].dataIndex]
            }`;
          },
        },
      },
    },
  };

  const co2exceeded = Math.round((Math.round(CO2Person) / 600) * 100);

  return (
    <div className="BarChart">
      {pageNum === 3 ? (
        <div className="resultDescription">
          <p>
            Die Emissionen, welche momentan jährlich für das Heizen Ihres
            Wohnraumes anfallen, betragen pro Kopf:
          </p>
          <span>
            <span className="text-4xl">{Math.round(CO2Person)}</span> kg
            CO₂-Äquivalent* pro Kopf und Jahr
          </span>
        </div>
      ) : pageNum === 4 ? (
        <div className="resultDescription">
          {co2exceeded <= 100 ? (
            <p>
              Mit den Emissionen, die für das Heizen Ihres Wohnraumes pro Kopf
              und Jahr anfallen, <br />
              haben Sie das CO₂-Budget gemäss BAFU zu{" "}
              <span className="text-4xl">{co2exceeded}%</span> aufgebraucht.
            </p>
          ) : (
            <p>
              Mit den Emissionen, die für das Heizen Ihres Wohnraumes pro Kopf
              und Jahr anfallen, <br />
              haben Sie das CO₂-Budget gemäss BAFU schon zu{" "}
              <span className="text-4xl">{co2exceeded - 100}%</span>{" "}
              überschritten.
            </p>
          )}
        </div>
      ) : (
        ""
      )}

      {window.innerWidth > 1120 ? (
        <div className="OverviewBarChart">
          <Overview
            selectedAnswers={selectedAnswers}
            sqrMeter={sqrMeter}
            persons={persons}
            changesPlaned={changesPlaned}
            selectedAnswersChanges={selectedAnswersChanges}
            sqrMeterChanges={sqrMeterChanges}
            personsChanges={personsChanges}
            setSelectedAnswers={setSelectedAnswers}
            setSqrMeter={setSqrMeter}
            setPersons={setPersons}
            setSelectedAnswersChanges={setSelectedAnswersChanges}
            setSqrMeterChanges={setSqrMeterChanges}
            setPersonsChanges={setPersonsChanges}
            setChangesPlaned={setChangesPlaned}
          />
          <div className="barChartWidth">
            <Bar data={data} options={options} />
            {pageNum === 3 ? (
              <p
                className="resultExplanation text1-sm"
                style={{
                  paddingLeft: "15%",
                  paddingRight: "15%",
                  textAlign: "left",
                }}
              >
                kg CO₂-Äquivalente* pro Person resp. kg CO₂-Äquivalente* pro
                Person und Jahr
              </p>
            ) : (
              <p
                className="resultExplanation text1-sm"
                style={{
                  paddingLeft: "15%",
                  paddingRight: "15%",
                  textAlign: "left",
                }}
              >
                kg CO₂-Äquivalente* pro Person und Jahr
              </p>
            )}
            <p
              style={{
                marginTop: "1.5em",
                fontSize: "0.6em",
                paddingLeft: "15%",
                paddingRight: "15%",
                textAlign: "left",
              }}
              className="resultExplanation text1-sm"
            >
              *Um die unterschiedliche Klimawirkung der verschiedenen
              Treibhausgase (THG) zu vereinheitlichen, werden die Emissionen
              verschiedener Substanzen in sogenannten CO2-Äquivalenten
              zusammengefasst.
            </p>
          </div>
        </div>
      ) : (
        <div className="overflow-x-auto">
          <br />
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                {(!changesPlaned && pageNum === 4) || pageNum === 7 ? (
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Handlung
                  </th>
                ) : (!changesPlaned && pageNum === 5) || pageNum === 8 ? (
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Messgrösse
                  </th>
                ) : (
                  ""
                )}

                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Ausstoss
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Berechnung
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Quelle
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200 ">
              {chartData.map((data, index) => {
                return <TableComponentBarChart key={index} data={data} />;
              })}
            </tbody>
            <br />
            <br />
          </table>
        </div>
      )}
    </div>
  );
};

export default BarChart;
