import React, {
  ChangeEventHandler,
  Dispatch,
  SetStateAction,
  useState,
} from "react";
import personG from "../../svg/PersonNum/grayPerson.svg";
import personO from "../../svg/PersonNum/orangePerson.svg";
import PlusIconG from "../../svg/PersonNum/GrayPlusIcon.svg";
import PlusIconO from "../../svg/PersonNum/OrangePlusIcon.svg";

import InputField from "./InputField";

interface Props {
  setPersons: Dispatch<SetStateAction<number | string>>;
  persons: number | string;
}

const Person = (props: Props) => {
  const { setPersons, persons } = props;
  const maxPerson = 8;
  const minPerson = 1;

  const handleButtonClick = (index: number) => {
    setPersons(index + 1);
  };
  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const newIndex = event.target.value;

    if (/^\d*$/.test(newIndex)) {
      setPersons(newIndex === "" ? "" : Number(newIndex));
    }
  };
  return (
    <div className="PerosnContainer">
      <h2 className="relative mb-10">Anzahl Personen im Haushalt</h2>
      <div style={{ display: "flex" }}>
        {[...Array(maxPerson)].map((_, index) => (
          <img
            src={persons >= index + 1 ? personO : personG}
            alt={`Person${index}`}
            key={index}
            onClick={() => handleButtonClick(index)}
            className="PersonImg"
          />
        ))}
        <img
          src={persons > 8 ? PlusIconO : PlusIconG}
          alt={`Person8`}
          key={8}
          onClick={() => handleButtonClick(8)}
          className="PersonImg"
        />
      </div>
      <div className="mt-10 TextInput">
        <InputField
          value={Number.isInteger(persons) ? Number(persons) : persons}
          setValue={setPersons}
          onChange={handleInputChange}
          description="Personen"
          minValue={minPerson}
        />
      </div>
    </div>
  );
};

export default Person;
